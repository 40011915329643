<template>
    <div class="painel-component">
      <v-card>
        <v-card-title>Componente do painel</v-card-title>
      </v-card>
    </div>
  </template>
    <script>
  import store from "@/store";
  import {
    onUnmounted,
    onMounted,
    ref,
    reactive,
    computed,
    nextTick,
    watch,
    onUpdated,
  } from "@vue/composition-api";
  
  export default {
    name: "Painel-Component",
    components: {},
    setup() {
      onMounted(() => {});

      return {
  
      };
    },
  };
  </script>
    
  <style lang="scss">
   
  </style>